<template>
    <modal ref="modalProductoDefecto" titulo="Productos por defecto del proveedor" icon="box" no-aceptar no-cancelar tamano="modal-lg">
        <div class="row mx-0 justify-center">
            <img :src="proveedor.imagen" class="obj-cover br-8 border" width="78" height="78" />
            <div class="col-4">
                <p class="text-general f-20 mt-2 f-600">
                    {{ proveedor.nombre }}
                </p>
                <p class="text-general f-17 f-600">
                    {{ proveedor.cantidad }} Productos
                </p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <tabla-general
                :data="tableData"
                header-cell-class-name="text-center text-general f-16 f-600"
                :mostrar-buscador="false"
                class-header="text-general f-16"
                :usar-paginacion="false"
                :alto="altoTabla"
                style="width: 100%"
                >
                    <el-table-column
                    width="80"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <img :src="scope.row.imagen || '/img/no-imagen/mercado.svg'" width="42" height="42" class="obj-cover br-8" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="detalle_producto" label="Producto" />
                    <el-table-column prop="valor" class-name="text-center" label="Valor de venta">
                        <template slot-scope="{ row }">
                            <div class="row mx-0 justify-content-center">
                                <div class="col-auto text-general2 f-15">
                                    {{ separadorNumero (row.valor) }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="text-center" label="CEDIS">
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-center">
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                        <div v-for="(data,d) in scope.row.cedisArray" :key="d" class="row mx-0 mb-1">
                                            {{ data.nombre }}
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        {{ scope.row.cedis }}
                                    </div>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </tabla-general>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{
            tableData: [],
            proveedor: {
                nombre: null,
                imagen: null,
                cantidad: null,
            },
            altoTabla: 'calc(100vh - 650px)'
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.changeSize();
        })
    },
    methods: {
        toggle(productos, proveedor){
            this.proveedor = _.cloneDeep(proveedor)
            this.tableData = _.cloneDeep(productos)
            this.$refs.modalProductoDefecto.toggle();
        },
        changeSize(){
            console.log('esto se armo');
            this.altoTabla = window.innerHeight <= 680 ? 'calc(100vh - 466px)' : 'calc(100vh - 650px)';    
        }
    }
}
</script>
